import React from 'react';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

interface WidgetActionsProps {
  onSave: any;
}

const WidgetActions: React.FC<WidgetActionsProps> = ({ onSave, ...props }) => {

  return (
    <div id="out-widget-actions" className="widget--row widget--row--actions">
      <div className="widget--actions-section-left"></div>
      <div className="widget--actions-section-center"></div>
      <div className="widget--actions-section-right">
        <button
          type="button"
          className="btn btn-primary widget-btn"
          onClick={onSave}
        >
          SAVE
          <RiSave3Fill className="widget-btn--icon" size="0.8rem" />
        </button>
      </div>
    </div>
  );
};

export default WidgetActions;
