export default {
  strings: {
    year: '2024',
    privacyPolicy: 'https://www.aircycler.com/policies/privacy-policy',
    cloudTerms: 'https://www.aircycler.com/policies/refund-policy',
  },
  loader: {
    timeout: 1000 * 25,
  },
  apis: {
    jitBE: {
      // baseURLs: ['http://localhost:8080/'],  // for local development
      // baseURLs: ['http://192.168.1.67:8080/'], // for local development
      // baseSocketURLs: ['http://192.168.1.67:8080'],
      baseURLs: ['https://aircycler.rwi-cloud.com/api/'],
      baseSocketURLs: [
        'wss://aircycler.rwi-cloud.com/',
      ],
    },
    g3iot: {
      // baseURLs: ['http://localhost:3000/local/'],
      // baseSocketURLs: [
      //   'ws://127.0.0.1:3001/local',
      //   'ws://127.0.0.1:3001/local',
      // ],
      baseURLs: ['https://g3iot.aircycler.io/'],
      baseSocketURLs: [
        'wss://ws-g3iot.aircycler.io',
        'wss://ws-g3iot.aircycler.io',
        'wss://ws-g3iot.aircycler.io/v1',
      ],
    },
    awair: {
      // redirectURI: 'http://localhost:4000/dashboard/awair',
      // clientId: '218fed37dc82476ab455c57d8e3de14f', // dev client ID
      auth: 'https://oauth-login.awair.is',
      redirectURI: 'https://aircycler.rwi-cloud.com/dashboard/awair',
      clientId: '951621ba35fa42728c8efcb4d6ab13e3',  // server client ID
    },
  },
};
