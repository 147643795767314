import React from 'react';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

interface FloatButtonProps {
  onSave: any;
}

const FloatButton: React.FC<FloatButtonProps> = ({onSave, ...props}) => {

  return (
    <div id="out-float-btn" className="float-btn--wrapper">
        <div className="float-btn--content" onClick={onSave}>
          SAVE
          <RiSave3Fill className="widget-btn--icon" size="0.8rem" />
        </div>
    </div>
  );
}

export default FloatButton;
