import React from 'react';
import UserProfileDropdown from '../UserProfileDropdown';
import Notification from '../Notification';

import './styles.scss';
interface HeaderProps {
  open?: any;
}

const Header: React.FC<HeaderProps> = ({ open }) => {
  return (
    <div id="user-header" className="header">
      <div className="navbar-collapse" id="navbarSupportedContent">
        <ul className="navbar-nav float-right d-flex flex-row align-items-center">
          <Notification />
          <UserProfileDropdown />
        </ul>
      </div>
    </div>
  );
};

export default Header;
