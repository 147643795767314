import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Dashboard as Home,
  DeviceSettings,
  DeleteAccount,
} from './pages';
import DataLoggingRoutes from '../dataLogging';
import MyProfile from '../profile';
import Systems from '../systems';
import Devices from '../devices';
import Foobot from '../foobot';
import AirThings from '../airthings';
import Awair from '../awair';
import ConfigurationsRouter from '../configurations';


const base_path = '/dashboard';

interface DashboardProps {
  userAccount: any;
  isUserInit: any;
  setUserInit: Function;
  loadFoobotUserData: Function;
  loadAirThingsUserData: Function;
  loadAwairState: Function;
}

const Dashboard: React.FC<DashboardProps> = ({ ...props }) => {


  useEffect(() => {
    if (props.userAccount && !props.isUserInit) {
      props.setUserInit();
      props.loadAirThingsUserData(props.userAccount);
      props.loadAwairState(props.userAccount);
    }
  }, [props.userAccount]);


  useEffect(() => {
    props.loadFoobotUserData();

    return () => {};
  }, []);


  return (
    <>
      <BreadcrumbsItem to={base_path}>Home</BreadcrumbsItem>
      <Switch>
        <Route exact path={base_path} component={Home} />
        <Route
          path={`${base_path}/system-settings/:id`}
          component={DeviceSettings}
        />
        <Route path={`${base_path}/systems`} component={Systems} />
        <Route path={`${base_path}/profile`} component={MyProfile} />
        <Route path={`${base_path}/devices`} component={Devices} />
        <Route path={`${base_path}/foobot`} component={Foobot} />
        <Route path={`${base_path}/airthings`} component={AirThings} />
        <Route path={`${base_path}/awair`} component={Awair} />
        <Route
          path={`${base_path}/data-logging`}
          component={DataLoggingRoutes}
        />
        <Route
          path={`${base_path}/configurations`}
          component={ConfigurationsRouter}
        />
        <Route
          path={`${base_path}/delete-account`}
          component={DeleteAccount}
        />
      </Switch>
    </>
  );
};

function mapStateToProps(state: any) {
  const { profile, env } = state;

  return {
    isUserInit: env.isUserInit,
    userAccount: profile.user,
  };
}

function mapDispatchToProps(dispatch: any) {
  return {
    loadFoobotUserData: dispatch.foobot.setFoobotUserData,
    loadAirThingsUserData: dispatch.airthings.loadAirthingsState,
    setUserInit: dispatch.env.setUserInit,
    loadAwairState: dispatch.awair.loadAwairState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
