import { ConnectionBroker } from '../../core';

export const logs = {
  state: {
    logsData: {},
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getDeviceLogs(payload: any) {
      const self: any = this;
      const { deviceKey } = payload || {};
      let response: any = await ConnectionBroker.fetchG3IotAPI(
        'jitG3Iot',
        'logs.list',
        deviceKey,
        null,
        {page: 1, items: 5},
      );
      if (response?.data) {
        self.updateState({ ...response?.data }, 'logsData');
      } else {
        // TODO: add error handler
      }
      return response?.data ? { ...response.data } : null;
    },
    async getProbeLaastValues(payload: any){
      const self: any = this;
      const { system_id, device_id }: any = payload || {};
      let response: any;

      if(system_id || device_id){
        const tempResp: any = await ConnectionBroker.fetchAPI(
          'jitBE',
          'systems.probeLastValues',
          null,
          { device_id: system_id || device_id },
        );

        if(tempResp?.statusCode === 200){
          response = tempResp?.body ? { ...tempResp.body } : null;
        }
      }else{
        console.log('Missing required system_id or device_id...');
      }

      return response;
    },
  }),
};
